.input-form {
  background-color: lightgray;
  padding: 1rem;
  margin: 2rem auto;
  width: 50rem;
  max-width: 95%;
  border-radius: 12px;
  text-align: center;
}

.input-form label {
  font: inherit;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem;
  text-align: left;
  color: rgb(22, 22, 22);
}

.input-form input {
  font: inherit;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 100px;
  border: 0px solid #ccc;
  width: 95%;
  max-width: 100%;
}

.input-form button {
  font: inherit;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 0px solid goldenrod;
  background-color: slategray;
  color: whitesmoke;
  border-radius: 100px;
  margin-right: 0rem;
  transition: background-color 300ms, border-color 300ms;
}

.input-form button:hover {
  background-color: grey;
  border-color: goldenrod;
}
.input-form button:active {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  background-color: grey;
  border-color: goldenrod;
}

.demo {
  text-align: center;
  height: 12%;
}

.demo button {
  font: inherit;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 0px solid goldenrod;
  background-color: slategray;
  color: whitesmoke;
  border-radius: 100px;
  margin-right: 0rem;
  transition: background-color 300ms, border-color 300ms;
}

.demo button:hover {
  background-color: grey;
  border-color: goldenrod;
}
.demo button:active {
  box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.12);
  background-color: grey;
  border-color: goldenrod;
}
