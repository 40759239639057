.user-list {
    background-color:#1e2124;
    padding: 1rem;
    margin: 1rem auto;
    width: 50rem;
    max-width: 95%;
    border-radius: 12px;
    text-align: center;

}

.user-list ul {
    padding: 0.5rem;
    margin: 2rem auto;
    width: 50rem;
    max-width: 95%;
}

.user-list.heading {
    font-size: 2rem;
    font-weight: bold;
    color:goldenrod;
}

.user-list p {
    color:whitesmoke;
}